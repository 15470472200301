import React from "react";
import { ChangeClientPasswordAPI, EditClientAPI } from "../service/UserService";
import { Encrypt } from "../utils/constants";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { editClientAPI } from "../service/AuthService";

export default function ClientChangePassword() {
  const [payload, setPayload] = React.useState({});
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const userType = params?.userType;
  const changePassword = async () => {
    if (!payload.masterPassword || !payload.newPassword)
      return toast.error("All fields are required");
    if (!payload.confirmPassword)
      return toast.error("Confirm Password is required");
    if (payload.newPassword !== payload.confirmPassword)
      return toast.error("Password and Confirm Password must be same");
    if (payload.newPassword.length < 3)
      return toast.error("Password must be atleast 3 characters long");
    if (payload.masterPassword.length < 3)
      return toast.error(
        "Master Password must be atleast 3 characters long and required"
      );
    const { response, code, status } = await EditClientAPI(
      {
        password: payload.newPassword,
        masterPassword: payload.masterPassword,
      },
      params.id
    );
    if (code == 200) {
      toast.success(status);
      setPayload({});
      navigate(`/`);
    } else {
    }
  };
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <app-change-user-password _nghost-ng-c3546741292="">
        <div _ngcontent-ng-c3546741292="" className="page_header">
          <h1 _ngcontent-ng-c3546741292="" className="page_heading">
            Change Password
          </h1>
          <div _ngcontent-ng-c3546741292="" className="breadcrumb_block">
            <nav _ngcontent-ng-c3546741292="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c3546741292="" className="breadcrumb">
                <li _ngcontent-ng-c3546741292="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3546741292="">Setting</a>
                </li>
                <li
                  _ngcontent-ng-c3546741292=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Change Password
                </li>
                <li _ngcontent-ng-c3546741292="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3546741292="" href="#/dashboard">
                    Back
                  </a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c3546741292=""
          className="Super_master_details_section agentledger_block"
        >
          <div
            _ngcontent-ng-c3546741292=""
            noValidate=""
            className="ng-untouched ng-pristine ng-valid"
          >
            <div
              _ngcontent-ng-c3546741292=""
              className="row g-3 align-items-end mb-4"
            >
              <div
                _ngcontent-ng-c3546741292=""
                className="col-sm-12 col-md-6 col-lg-3"
              >
                <div _ngcontent-ng-c3546741292="" className="input_block">
                  <label _ngcontent-ng-c3546741292="">Master Password </label>
                  <input
                    _ngcontent-ng-c3546741292=""
                    formcontrolname="mass_pass"
                    type="password"
                    id="name"
                    name="name"
                    onChange={(e) =>
                      setPayload({
                        ...payload,
                        masterPassword: e.target.value,
                      })
                    }
                    value={payload.masterPassword}
                    className="form-control ng-untouched ng-pristine ng-valid"
                  />
                </div>
              </div>
              <div
                _ngcontent-ng-c3546741292=""
                className="col-sm-12 col-md-6 col-lg-3"
              >
                <div _ngcontent-ng-c3546741292="" className="input_block">
                  <label _ngcontent-ng-c3546741292="">New Password </label>
                  <input
                    _ngcontent-ng-c3546741292=""
                    formcontrolname="newPass"
                    type="password"
                    id="newpaswrd"
                    name="newpaswrd"
                    onChange={(e) =>
                      setPayload({
                        ...payload,
                        newPassword: e.target.value,
                      })
                    }
                    value={payload.newPassword}
                    className="form-control ng-untouched ng-pristine ng-valid"
                  />
                </div>
              </div>
              <div
                _ngcontent-ng-c3546741292=""
                className="col-sm-12 col-md-6 col-lg-3"
              >
                <div _ngcontent-ng-c3546741292="" className="input_block">
                  <label _ngcontent-ng-c3546741292="">
                    Confirm Password{" "}
                  </label>
                  <input
                    _ngcontent-ng-c3546741292=""
                    formcontrolname="confirmPass"
                    type="password"
                    id="confirmpasswrd"
                    name="confirmpasswrd"
                    onChange={(e) =>
                      setPayload({
                        ...payload,
                        confirmPassword: e.target.value,
                      })
                    }
                    value={payload.confirmPassword}
                    className="form-control ng-untouched ng-pristine ng-valid"
                  />
                </div>
              </div>
              <div
                _ngcontent-ng-c3546741292=""
                className="col-sm-12 col-md-6 col-lg-3"
              >
                <div _ngcontent-ng-c3546741292="" className="input_block">
                  <button
                    _ngcontent-ng-c3546741292=""
                    type="button"
                    onClick={changePassword}
                    className="btn btn-primary"
                  >
                    Change Password
                  </button>
                  <button
                    _ngcontent-ng-c3546741292=""
                    type="button"
                    className="btn btn-secondary ms-2"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-change-user-password>
    </div>
  );
}
