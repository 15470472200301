import React, { useEffect, useState } from "react";
import {
  getCommissionReportAPI,
  getCommissionReportByUserAPI,
  resetCommissionReportByUserAPI,
} from "../service/AuthService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
export default function ComissionLenaDena() {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState();
  const [payload, setPayload] = useState({
    startDate: moment()
      .subtract(20, "days")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    userId: "",
    page: 0,
  });
  const getCommissionReport = async () => {
    const { response, code } = await getCommissionReportAPI();
    if (code == 200) {
      setData(response.commissionReport);
      setFilterData(response.commissionReport);
    }
  };
  const getCommissionReportByUser = async (clientId) => {
    const { response, code } = await getCommissionReportByUserAPI(clientId, {
      startDate: moment(payload.startDate).unix() * 1000,
      endDate: moment(payload.endDate).unix() * 1000,
      page: payload.page,
      userId: payload.userId,
    });
    if (code == 200) {
      setData(response.commissionReport?.content);
      setFilterData(response.commissionReport?.content);
    }
  };
  const resetComm = async (clientId) => {
    const { response, code } = await resetCommissionReportByUserAPI(clientId);
    if (code == 200) {
      toast.success("Commission Reset Successfully");
      getCommissionReport();
    }
  };
  const applyFilter = async () => {
    await getCommissionReportByUser(payload.userId);
  };
  useEffect(() => {
    getCommissionReport();
  }, []);
  useEffect(() => {
    if (payload.userId) {
      getCommissionReportByUser(payload.userId);
    }
  }, [payload.startDate, payload.endDate, payload.userId]);
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-commission-lena-dena
        _nghost-ng-c2164784703=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c2164784703="" className="page_header">
          <h1 _ngcontent-ng-c2164784703="" className="page_heading">
            Commission Lena Dena
          </h1>
          <div _ngcontent-ng-c2164784703="" className="breadcrumb_block">
            <nav _ngcontent-ng-c2164784703="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c2164784703="" className="breadcrumb">
                <li _ngcontent-ng-c2164784703="" className="breadcrumb-item">
                  <a _ngcontent-ng-c2164784703="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c2164784703=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Commission Lena Dena
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c2164784703=""
          className="Super_master_details_section agentledger_block"
        >
          <div
            _ngcontent-ng-c2164784703=""
            className="row g-3 align-items-end mb-4"
          >
            <div
              _ngcontent-ng-c2164784703=""
              className="col-sm-12 col-md-6 col-lg-3"
            >
              <div
                _ngcontent-ng-c2164784703=""
                className="input_block position-relative"
              >
                <label _ngcontent-ng-c2164784703="">Select Client</label>
                <Select
                  onChange={(e) => {
                    setPayload({ ...payload, userId: e.value });
                  }}
                  options={data?.map((item) => ({
                    value: item.clientId,
                    label: item.clientUsername,
                  }))}
                />
              </div>
            </div>
            {payload.userId && (
              <>
                <div
                  _ngcontent-ng-c2164784703=""
                  className="col-md-6 col-lg-3 col-6"
                >
                  <div _ngcontent-ng-c2164784703="" className="input_block">
                    <label _ngcontent-ng-c2164784703="">Date From</label>
                    <input
                      _ngcontent-ng-c2164784703=""
                      type="date"
                      id="name"
                      name="name"
                      value={moment(payload.startDate).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setPayload({ ...payload, startDate: e.target.value });
                      }}
                      className="form-control ng-valid ng-dirty ng-touched"
                    />
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2164784703=""
                  className="col-md-6 col-lg-3 col-6"
                >
                  <div _ngcontent-ng-c2164784703="" className="input_block">
                    <label _ngcontent-ng-c2164784703="">Date To</label>
                    <input
                      _ngcontent-ng-c2164784703=""
                      type="date"
                      id="name"
                      value={moment(payload.endDate).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setPayload({ ...payload, endDate: e.target.value });
                      }}
                      name="name"
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
              </>
            )}
            <div
              _ngcontent-ng-c2164784703=""
              className="col-sm-12 col-md-6 col-lg-3"
            >
              <div _ngcontent-ng-c2164784703="" className="input_block">
                <button
                  _ngcontent-ng-c2164784703=""
                  type="button"
                  onClick={applyFilter}
                  className="btn btn-primary"
                >
                  Submit
                </button>
                <button
                  _ngcontent-ng-c2164784703=""
                  type="button"
                  onClick={getCommissionReport}
                  className="btn btn-primary"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div _ngcontent-ng-c2164784703="" className="custom_table">
            <div _ngcontent-ng-c2164784703="" className="table-responsive">
              <table _ngcontent-ng-c2164784703="" className="table">
                <thead _ngcontent-ng-c2164784703="">
                  <tr _ngcontent-ng-c2164784703="">
                    {payload?.userId && (
                      <th
                        _ngcontent-ng-c2164784703=""
                        className="ng-star-inserted"
                      >
                        Date
                      </th>
                    )}
                    <th
                      _ngcontent-ng-c2164784703=""
                      className="ng-star-inserted"
                    >
                      {" "}
                      Name
                    </th>
                    {/**/}
                    {/**/}
                    {/**/}
                    <th
                      style={{
                        width: "60px",
                      }}
                      _ngcontent-ng-c2164784703=""
                    >
                      M.Comm.
                    </th>
                    <th
                      style={{
                        width: "40px",
                      }}
                      _ngcontent-ng-c2164784703=""
                    >
                      S.Comm.{" "}
                    </th>
                    <th
                      style={{
                        width: "40px",
                      }}
                      _ngcontent-ng-c2164784703=""
                    >
                      C.Comm.
                    </th>
                    <th
                      style={{
                        width: "40px",
                      }}
                      _ngcontent-ng-c2164784703=""
                    >
                      T.Comm.
                    </th>
                    <th
                      style={{
                        width: "40px",
                      }}
                      _ngcontent-ng-c2164784703=""
                    >
                      M.Comm.
                    </th>
                    <th
                      style={{
                        width: "40px",
                      }}
                      _ngcontent-ng-c2164784703=""
                    >
                      S.Comm.
                    </th>
                    <th
                      style={{
                        width: "40px",
                      }}
                      _ngcontent-ng-c2164784703=""
                    >
                      C.Comm.
                    </th>
                    <th
                      style={{
                        width: "40px",
                      }}
                      _ngcontent-ng-c2164784703=""
                    >
                      T.Comm.
                    </th>
                  </tr>
                </thead>
                <tbody
                  _ngcontent-ng-c2164784703=""
                  className="ng-star-inserted"
                >
                  <tr _ngcontent-ng-c2164784703="">
                    <td _ngcontent-ng-c2164784703="" />
                    {/**/}
                    <td
                      _ngcontent-ng-c2164784703=""
                      colSpan={4}
                      className="bg_green"
                    >
                      <b _ngcontent-ng-c2164784703="">
                        PAYMENT RECEIVING FROM (MILA HE)
                      </b>
                    </td>
                    <td
                      _ngcontent-ng-c2164784703=""
                      colSpan={5}
                      className="bg_red"
                    >
                      <b _ngcontent-ng-c2164784703="">
                        PAYMENT PAID TO (DIYA HE)
                      </b>
                    </td>
                  </tr>
                  {filterData?.map((item) => (
                    <tr
                      _ngcontent-ng-c2164784703=""
                      className="ng-star-inserted"
                    >
                      {payload?.userId && (
                        <td>
                          {moment(item?.createdAt).format("YYYY-MM-DD HH:mm")}
                        </td>
                      )}
                      <td
                        _ngcontent-ng-c2164784703=""
                        className="ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c2164784703=""
                          className="reset_btn"
                        >
                          {" "}
                          {item.clientUsername
                            ? item?.clientUsername
                            : item?.eventName}{" "}
                          <br _ngcontent-ng-c2164784703="" />{" "}
                          {!payload?.userId && (
                            <button
                              onClick={() => resetComm(item.clientId)}
                              _ngcontent-ng-c2164784703=""
                              type="button"
                              className="btn btn-primary"
                            >
                              Reset
                            </button>
                          )}
                        </div>
                      </td>
                      {/**/}
                      {/**/}
                      {/**/}
                      <td _ngcontent-ng-c2164784703="">
                        {parseFloat(item?.clientMatchCommission).toFixed(2)}
                      </td>
                      <td _ngcontent-ng-c2164784703="">
                        {item?.clientSessionCommission}
                      </td>
                      <td _ngcontent-ng-c2164784703="">
                        {item?.clientCasinoCommission}
                      </td>
                      <td _ngcontent-ng-c2164784703="">
                        {parseFloat(
                          item.clientMatchCommission +
                            item.clientSessionCommission +
                            item.clientCasinoCommission
                        ).toFixed(2)}
                      </td>
                      <td _ngcontent-ng-c2164784703="">
                        {parseFloat(item?.agentMatchCommission).toFixed(2)}
                      </td>
                      <td _ngcontent-ng-c2164784703="">
                        {item?.agentSessionCommission}
                      </td>
                      <td _ngcontent-ng-c2164784703="">
                        {item?.agentCasinoCommission}
                      </td>
                      <td>
                        {parseFloat(
                          item.agentCasinoCommission +
                            item.agentMatchCommission +
                            item.agentSessionCommission
                        ).toFixed(2)}
                      </td>
                    </tr>
                  ))}

                  {/**/}
                </tbody>
                {/**/}
                <tfoot _ngcontent-ng-c2164784703="">
                  <tr
                    _ngcontent-ng-c2164784703=""
                    className="ng-star-inserted"
                  >
                    <td _ngcontent-ng-c2164784703="">Total</td>
                    <td>
                      {data?.reduce((a, b) => a + b.clientMatchCommission, 0)}
                    </td>
                    <td>
                      {data?.reduce(
                        (a, b) => a + b.clientSessionCommission,
                        0
                      )}
                    </td>
                    <td>
                      {data?.reduce(
                        (a, b) => a + b.clientCasinoCommission,
                        0
                      )}
                    </td>
                    <td>
                      {data?.reduce(
                        (a, b) =>
                          a +
                          b.clientMatchCommission +
                          b.clientSessionCommission +
                          b.clientCasinoCommission,
                        0
                      )}
                    </td>
                    <td>
                      {parseFloat(
                        data?.reduce((a, b) => a + b.agentMatchCommission, 0)
                      ).toFixed(2)}
                    </td>
                    <td>
                      {data?.reduce(
                        (a, b) => a + b.agentSessionCommission,
                        0
                      )}
                    </td>
                    <td>
                      {data?.reduce((a, b) => a + b.agentCasinoCommission, 0)}
                    </td>
                    <td>
                      {data?.reduce(
                        (a, b) =>
                          a +
                          b.agentCasinoCommission +
                          b.agentMatchCommission +
                          b.agentSessionCommission,
                        0
                      )}
                    </td>
                  </tr>
                  {/**/}
                </tfoot>
                {/**/}
              </table>
            </div>
          </div>
        </div>
      </app-commission-lena-dena>
      {/**/}
    </div>
  );
}
