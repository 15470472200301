import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getChildrenByEventId,
  getMatchAndSessionBetsAPI,
} from "../service/AuthService";
import moment from "moment";
import Select from "react-select";
import ReactSelect from "react-select";
export default function DisplayMatchSession() {
  const location = useLocation();
  const { id } = useParams();
  const [client, setClient] = useState([]);
  const [clients, setClients] = useState([]);
  const [data, setData] = useState([]);
  const [aggregate, setAggregate] = useState({
    match: {
      amount: 0,
      a: 0,
      b: 0,
    },
    session: {
      amount: 0,
      a: 0,
      b: 0,
    },
  });
  const getMatchAndSessionBets = async (clientId) => {
    const { response } = await getMatchAndSessionBetsAPI(clientId, id);
    setData(response);
    let uniqueRunnerNames = Array.from(
      new Set(response?.bookmakerBets?.map((item) => item?.runnerName))
    );
    setAggregate({
      match: {
        amount: response?.bookmakerBets?.reduce(
          (acc, item) => acc + item.amount,
          0
        ),
        a: response?.bookmakerBets
          ?.filter((item) => item?.runnerName == uniqueRunnerNames[0])
          ?.reduce((acc, item) => acc + item.upperLineExposure[0].exposure, 0),
        b: response?.bookmakerBets
          ?.filter((item) => item?.runnerName == uniqueRunnerNames[1])
          .reduce((acc, item) => acc + item.upperLineExposure[1].exposure, 0),
      },
      session: {
        amount: response?.fancyBets?.reduce(
          (acc, item) => acc + item.amount,
          0
        ),
        a: response?.fancyBets
          ?.filter((item) => item.back)
          .reduce((acc, item) => acc + item.amount, 0),
        b: response?.fancyBets
          ?.filter((item) => !item.back)
          .reduce((acc, item) => acc + item.amount, 0),
      },
    });
  };
  const getClients = async () => {
    const { response } = await getChildrenByEventId(id, true);
    setClients(response?.children);
  };
  useEffect(() => {
    getClients();
  }, [id]);
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-match-session-bets
        _nghost-ng-c2609501654=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c2609501654="" className="page_header">
          <h1 _ngcontent-ng-c2609501654="" className="page_heading">
            Match &amp; Session Bets
          </h1>
          <div _ngcontent-ng-c2609501654="" className="breadcrumb_block">
            <nav _ngcontent-ng-c2609501654="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c2609501654="" className="breadcrumb">
                <li _ngcontent-ng-c2609501654="" className="breadcrumb-item">
                  <a _ngcontent-ng-c2609501654="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li _ngcontent-ng-c2609501654="" className="breadcrumb-item">
                  <a _ngcontent-ng-c2609501654="">Sport's Betting </a>
                </li>
                <li
                  _ngcontent-ng-c2609501654=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  {" "}
                  Match &amp; Session Bets{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div _ngcontent-ng-c2609501654="" className="top_btn_block">
          <button
            _ngcontent-ng-c2609501654=""
            className="btn btn-primary"
            tabIndex={0}
          >
            Match &amp; Session Plus Minus
          </button>
          <button
            _ngcontent-ng-c2609501654=""
            className="btn btn-primary"
            tabIndex={0}
          >
            Display Match &amp; Session Bets
          </button>
          <button
            _ngcontent-ng-c2609501654=""
            className="btn btn-primary"
            tabIndex={0}
            onClick={() => {
              window.location.href = `/game/i/${
                location?.state?.eventId
              }/viewMatchReport`;
            }}
          >
            Display Match Bets
          </button>
          <button
            _ngcontent-ng-c2609501654=""
            className="btn btn-primary"
            tabIndex={0}
            onClick={() => {
              window.location.href = `/game/i/${
                location?.state?.eventId
              }/viewSessionReport`;
            }}
          >
            Display Session Bets
          </button>
        </div>
        <div
          _ngcontent-ng-c2609501654=""
          className="Super_master_details_section agentledger_block"
        >
          <div
            _ngcontent-ng-c2609501654=""
            className="col-sm-12 col-md-6 col-lg-3"
          >
            <div
              _ngcontent-ng-c2609501654=""
              className="input_block custom_select_block position-relative"
            >
              <label _ngcontent-ng-c2609501654="">Client</label>
              <ReactSelect
                required=""
                id="name"
                placeholder="Select Client"
                name="client"
                data-select2-id="name"
                tabIndex={-1}
                aria-hidden="true"
                options={clients?.map((item, index) => ({
                  value: item?.uid,
                  label: item.name + " " + item.username,
                }))}
                onChange={(e) => {
                  getMatchAndSessionBets(e.value);
                }}
              />
            </div>
          </div>
          <div _ngcontent-ng-c2609501654="" className="row g-3 mt-3">
            <div
              _ngcontent-ng-c2609501654=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-6"
            >
              <div _ngcontent-ng-c2609501654="" className="custom_table">
                <div
                  _ngcontent-ng-c2609501654=""
                  className="table-responsive"
                >
                  <table _ngcontent-ng-c2609501654="" className="table">
                    <thead _ngcontent-ng-c2609501654="">
                      <tr _ngcontent-ng-c2609501654="">
                        <th _ngcontent-ng-c2609501654="" colSpan={9}>
                          <h3
                            _ngcontent-ng-c2609501654=""
                            className="page_heading"
                          >
                            Match Bets : P&amp;L{" "}
                            <span _ngcontent-ng-c2609501654="">
                              {Math.abs(
                                data?.bookmakerBets?.reduce(
                                  (acc, item) => acc + item?.clientPlusMinus,

                                  0
                                )
                              ).toFixed(2)}{" "}
                            </span>
                          </h3>
                        </th>
                      </tr>
                      <tr _ngcontent-ng-c2609501654="">
                        <th _ngcontent-ng-c2609501654="">Sr</th>
                        <th _ngcontent-ng-c2609501654="">Rate</th>
                        <th _ngcontent-ng-c2609501654="">Mode</th>
                        <th _ngcontent-ng-c2609501654="">Team</th>
                        <th _ngcontent-ng-c2609501654="">Amount</th>
                        {data?.bookmakerBets &&
                          data?.bookmakerBets[0]?.upperLineExposure?.map(
                            (item, index) => (
                              <th _ngcontent-ng-c2609501654="">
                                {item.selectionName}
                              </th>
                            )
                          )}
                        <th _ngcontent-ng-c2609501654="">WNR</th>
                        <th _ngcontent-ng-c2609501654="">Date &amp; Time</th>
                      </tr>
                    </thead>
                    <tbody _ngcontent-ng-c2609501654="">
                      {data?.bookmakerBets?.map((item, index) => (
                        <tr
                          _ngcontent-ng-c2609501654=""
                          className="ng-star-inserted"
                        >
                          <td _ngcontent-ng-c2609501654="">{index + 1}</td>
                          <td _ngcontent-ng-c2609501654="">{item.rate}</td>
                          <td _ngcontent-ng-c2609501654="">
                            {item?.back ? "LAGAI" : "KHAI"}
                          </td>
                          <td _ngcontent-ng-c2609501654="">
                            {item?.runnerName}
                          </td>
                          <td _ngcontent-ng-c2609501654="">{item?.amount}</td>
                          {item?.upperLineExposure?.map((item, index) => (
                            <td
                              _ngcontent-ng-c2609501654=""
                              className={
                                item?.exposure > 0
                                  ? "plus_value"
                                  : "mainus_value"
                              }
                            >
                              {item?.exposure}
                            </td>
                          ))}

                          <td _ngcontent-ng-c2609501654="">{item?.winner}</td>
                          <td _ngcontent-ng-c2609501654="">
                            {moment(item.placeDate + "Z").format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot _ngcontent-ng-c2609501654="">
                      <tr _ngcontent-ng-c2609501654="">
                        <td _ngcontent-ng-c2609501654="" colSpan={4}>
                          Total
                        </td>
                        <td _ngcontent-ng-c2609501654="">
                          {data?.bookmakerBets?.reduce(
                            (acc, item) => acc + item?.amount,

                            0
                          )}
                        </td>
                        <td
                          _ngcontent-ng-c2609501654=""
                          className={"mainus_value"}
                        >
                          {parseFloat(
                            data?.bookmakerBets?.reduce(
                              (total, item) =>
                                total + item.upperLineExposure[0]?.exposure,
                              0
                            )
                          ).toFixed(2)}
                        </td>
                        <td
                          _ngcontent-ng-c2609501654=""
                          className="plus_value"
                        >
                          {parseFloat(
                            data?.bookmakerBets?.reduce(
                              (total, item) =>
                                total + item.upperLineExposure[1]?.exposure,
                              0
                            )
                          ).toFixed(2)}
                        </td>
                        <td _ngcontent-ng-c2609501654="" />
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c2609501654=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-6"
            >
              <div _ngcontent-ng-c2609501654="" className="custom_table">
                <div
                  _ngcontent-ng-c2609501654=""
                  className="table-responsive"
                >
                  <table _ngcontent-ng-c2609501654="" className="table">
                    <thead _ngcontent-ng-c2609501654="">
                      <tr _ngcontent-ng-c2609501654="">
                        <th _ngcontent-ng-c2609501654="" colSpan={12}>
                          <h3
                            _ngcontent-ng-c2609501654=""
                            className="page_heading"
                          >
                            Session Bets : P&amp;L{" "}
                            <span
                              _ngcontent-ng-c2609501654=""
                              className={
                                data?.fancyBets?.reduce(
                                  (acc, item) => acc + item?.clientPlusMinus,

                                  0
                                ) > 0
                                  ? "plus_value"
                                  : "mainus_value"
                              }
                            >
                              {data?.fancyBets?.reduce(
                                (acc, item) => acc + item?.clientPlusMinus,

                                0
                              )}
                            </span>
                          </h3>
                        </th>
                      </tr>
                      <tr _ngcontent-ng-c2609501654="">
                        <th _ngcontent-ng-c2609501654="">Sr</th>
                        <th _ngcontent-ng-c2609501654="">Session</th>
                        <th _ngcontent-ng-c2609501654="">Run</th>
                        <th _ngcontent-ng-c2609501654="">Rate</th>
                        <th _ngcontent-ng-c2609501654="">Mode</th>

                        <th _ngcontent-ng-c2609501654="">Amount</th>
                        <th _ngcontent-ng-c2609501654="">Dec</th>
                        <th _ngcontent-ng-c2609501654="">Date &amp; Time</th>
                      </tr>
                    </thead>
                    <tbody _ngcontent-ng-c2609501654="">
                      {data?.fancyBets?.map((item, index) => (
                        <tr
                          _ngcontent-ng-c2609501654=""
                          className="ng-star-inserted"
                        >
                          <td _ngcontent-ng-c2609501654="">{index + 1}</td>
                          <td _ngcontent-ng-c2609501654="">
                            {item?.runnerName}
                          </td>
                          <td _ngcontent-ng-c2609501654="">{item.run}</td>
                          <td _ngcontent-ng-c2609501654="">{item.rate}</td>
                          <td _ngcontent-ng-c2609501654="">
                            {item?.back ? "YES" : "NO"}
                          </td>

                          <td _ngcontent-ng-c2609501654="">{item?.amount}</td>
                          <td
                            _ngcontent-ng-c2609501654=""
                            className="mainus_value"
                          >
                            {item?.winner}
                          </td>
                          <td _ngcontent-ng-c2609501654="">
                            {moment(item.placeDate + "Z").format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-match-session-bets>
      {/**/}
    </div>
  );
}
