import React, { useEffect } from "react";
import { getCurrentDateTimeLocal } from "../utils/constants";
import useSound from "use-sound";

import ping from "../assets/ping.mp3";
import moment from "moment";
import { unSettledBetsAPI } from "../service/AuthService";
import { Button, Row } from "react-bootstrap";
export default function CurrentBets() {
  const [sport, setSport] = React.useState("MATCHED");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(500);
  const [data, setData] = React.useState([]);
  const [search_, setSearch] = React.useState("");
  const [filter, setFilter] = React.useState("All");
  const [runnerNames, setRunnerNames] = React.useState([]);
  const [eventName, setEventName] = React.useState([]);
  const [filterPayload, setFilterPayload] = React.useState({});
  const [play] = useSound(ping);
  const getBetHistory = async (userName) => {
    let payload = {
      betStatus: sport,
      pageNumber: page,
      pageSize: size,
      gameType: filter.market ? filter.market : undefined,
    };

    if (search_.length > 0) {
      payload["search"] = userName;
    }
    const {
      response: { content },
    } = await unSettledBetsAPI(payload);
    let data_ = content.map((item, index) => ({
      ...item,
      bet_type: item.back_rate || item.back_run ? "Back" : "Lay",
      rate: item.back_run ? item.back_run : item.lay_run,
      "p/l": item.profit ? item.profit : item.loss,
    }));
    let data__ = JSON.parse(localStorage.getItem("betHistory"));
    if (localStorage.getItem("betHistory")) {
      if (content[0]?.created_date != data__[0]?.created_date) {
        sendNotification(
          "New Bet Placed",
          `Bet Placed on ${content[0]?.event_name} by ${content[0]?.userName}`
        );
        play();
      }
    }
    localStorage.setItem("betHistory", JSON.stringify(data_));

    setRunnerNames(
      Array.from(new Set(data_?.map((item) => item?.runner_name)))
    );
    setEventName(Array.from(new Set(data_?.map((item) => item?.event_name))));

    setData(data_);
  };
  useEffect(() => {
    getBetHistory(search_);
  }, [size, page, search_]);
  const handleSubmit = () => {};

  const nextPage = () => {
    if (data.length > 0) {
      setPage(page + 1);
    }
  };
  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  const sendNotification = (title, body) => {
    play();
    if (Notification.permission === "granted") {
      new Notification(title, {
        body,
      });
    }
  };
  const enableNotification = () => {
    console.log("enableNotification");
    Notification.requestPermission();
  };
  const handleFilter = () => {
    let data_ = [...data];
    if (filterPayload.runnerName) {
      data_ = data_.filter(
        (item) => item.runner_name === filterPayload.runnerName
      );
    }
    if (filterPayload.eventName) {
      data_ = data_.filter(
        (item) => item.event_name === filterPayload.eventName
      );
    }
    if (filterPayload.betType) {
      data_ = data_.filter((item) => item.bet_type === filterPayload.betType);
    }
    if (filterPayload.userName) {
      data_ = data_.filter(
        (item) => item.userName === filterPayload.userName
      );
    }
    if (filterPayload.bhav) {
      data_ = data_.filter((item) => item.rate === filterPayload.bhav);
    }
    if (filterPayload.fromDate) {
      data_ = data_.filter(
        (item) =>
          new Date(item.created_date).getTime() >=
          new Date(filterPayload.fromDate).getTime()
      );
    }
    if (filterPayload.toDate) {
      data_ = data_.filter(
        (item) =>
          new Date(item.created_date).getTime() <=
          new Date(filterPayload.toDate).getTime()
      );
    }

    setData(data_);
  };
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-current-bets
        _nghost-ng-c3386150819=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c3386150819="" className="page_header">
          <h1 _ngcontent-ng-c3386150819="" className="page_heading">
            Current Bets
          </h1>
          <div _ngcontent-ng-c3386150819="" className="breadcrumb_block">
            <nav _ngcontent-ng-c3386150819="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c3386150819="" className="breadcrumb">
                <li _ngcontent-ng-c3386150819="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3386150819="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c3386150819=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Current Bets
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c3386150819=""
          className="Super_master_details_section agentledger_block"
        >
          <div
            _ngcontent-ng-c3386150819=""
            className="row g-lg-3 g-2 align-items-end mb-4"
          >
            <div
              _ngcontent-ng-c3386150819=""
              className="col-6 col-md-6 col-lg-6"
            >
              <div _ngcontent-ng-c3386150819="" className="input_block">
                <label _ngcontent-ng-c3386150819="">Team Name</label>
                <select
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      event_name: e.target.value,
                    })
                  }
                  _ngcontent-ng-c3386150819=""
                  name="s_type"
                  className="form-control form-select ng-untouched ng-pristine ng-valid"
                >
                  {eventName?.map((item, index) => (
                    <option value={item}>
                      {item +
                        " " +
                        moment(
                          data?.find((item_) => item_?.event_name == item)
                            ?.created_date + "Z"
                        )
                          ?.toDate()
                          .toLocaleString()}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              _ngcontent-ng-c3386150819=""
              className="col-6 col-md-6 col-lg-6"
            >
              <div _ngcontent-ng-c3386150819="" className="input_block">
                <label _ngcontent-ng-c3386150819="">Market</label>
                <select
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      market: e.target.value,
                    })
                  }
                  _ngcontent-ng-c3386150819=""
                  name="s_type"
                  className="form-control form-select ng-untouched ng-pristine ng-valid"
                >
                  <option value={""}>All</option>
                  <option value="FANCY">Fancy</option>
                  <option value="MATCH">Bookmaker</option>
                </select>
              </div>
            </div>
            {filter?.market == "FANCY" && (
              <div
                _ngcontent-ng-c3386150819=""
                className="col-6 col-md-6 col-lg-6"
              >
                <div _ngcontent-ng-c3386150819="" className="input_block">
                  <label _ngcontent-ng-c3386150819="">Market</label>
                  <select
                    value={filter.runner_name}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        runner_name: e.target.value,
                      })
                    }
                    _ngcontent-ng-c3386150819=""
                    name="s_type"
                    className="form-control form-select ng-untouched ng-pristine ng-valid"
                  >
                    <option value={""}>All</option>
                    {Array.from(
                      new Set(
                        data
                          ?.filter((item) => {
                            if (filter.event_name) {
                              return item?.event_name == filter.event_name;
                            } else {
                              return item;
                            }
                          })
                          .map((item) => item?.runner_name)
                      )
                    )?.map((item, index) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div
              _ngcontent-ng-c3386150819=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div _ngcontent-ng-c3386150819="" className="input_block">
                <button
                  _ngcontent-ng-c3386150819=""
                  type="button"
                  onClick={handleFilter}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c3386150819=""
            className="custom_table ng-star-inserted"
          >
            <div
              _ngcontent-ng-c3386150819=""
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <div _ngcontent-ng-c3386150819="" className="shoe_entery" />
            </div>
            <div _ngcontent-ng-c3386150819="" className="table-responsive">
              <table _ngcontent-ng-c3386150819="" className="table">
                <thead _ngcontent-ng-c3386150819="">
                  <tr _ngcontent-ng-c3386150819="">
                    {/**/}
                    <th _ngcontent-ng-c3386150819="">Event Type </th>
                    <th _ngcontent-ng-c3386150819="">Event Name</th>
                    <th _ngcontent-ng-c3386150819="">Username</th>
                    <th _ngcontent-ng-c3386150819="">Runner Name</th>
                    <th _ngcontent-ng-c3386150819="">Bet Type</th>
                    <th _ngcontent-ng-c3386150819="">Run/Bhav</th>
                    <th _ngcontent-ng-c3386150819="">Amount</th>
                    <th _ngcontent-ng-c3386150819="">Place Date</th>
                    <th _ngcontent-ng-c3386150819="">Match Date</th>
                  </tr>
                </thead>
                <tbody _ngcontent-ng-c3386150819="">
                  {data?.map((item) => (
                    <tr
                      _ngcontent-ng-c3386150819=""
                      className={`odd ${
                        item.bet_type == "Back" ? "back" : "lay"
                      } ng-star-inserted`}
                    >
                      {/**/}
                      <td _ngcontent-ng-c3386150819="">{item?.event_type}</td>
                      <td _ngcontent-ng-c3386150819="">{item.event_name}</td>
                      <td _ngcontent-ng-c3386150819="">{item.userName}</td>
                      <td _ngcontent-ng-c3386150819="">{item.runner_name}</td>
                      <td _ngcontent-ng-c3386150819="">{item.bet_type}</td>
                      <td _ngcontent-ng-c3386150819="">{item.rate}</td>
                      <td _ngcontent-ng-c3386150819="">{item.amount}</td>
                      <td _ngcontent-ng-c3386150819="">
                        <div
                          _ngcontent-ng-c3386150819=""
                          className="Date_time_box"
                        >
                          {moment(item.created_date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      </td>
                      <td _ngcontent-ng-c3386150819="">
                        <div
                          _ngcontent-ng-c3386150819=""
                          className="Date_time_box"
                        >
                          {moment(item.created_date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                _ngcontent-ng-c3386150819=""
                className="d-flex justify-content-center"
                style={{ textAlign: "center" }}
              >
                <div
                  _ngcontent-ng-c3386150819=""
                  className="col-sm-12 ng-star-inserted"
                >
                  <pagination-controls
                    _ngcontent-ng-c3386150819=""
                    className="my-pagination"
                  >
                    <pagination-template>
                      <nav role="navigation" aria-label="Pagination">
                        <ul className="ngx-pagination ng-star-inserted">
                          <li className="pagination-previous ng-star-inserted">
                            {/**/}
                            <span
                              onClick={prevPage}
                              className="ng-star-inserted"
                            >
                              {" "}
                              Previous{" "}
                              <span className="show-for-sr">page</span>
                            </span>
                            {/**/}
                          </li>
                          {/**/}
                          <li className="small-screen"> {page}</li>
                          <li className="current ng-star-inserted">
                            {/**/}
                            <span
                              aria-live="polite"
                              className="ng-star-inserted"
                            >
                              <span className="show-for-sr">
                                You're on page{" "}
                              </span>
                              <span>{page + 1}</span>
                            </span>
                            {/**/}
                            {/**/}
                          </li>
                          <li className="ng-star-inserted">
                            <a tabIndex={0} className="ng-star-inserted">
                              <span className="show-for-sr">page </span>
                              <span>{page + 2}</span>
                            </a>
                            {/**/}
                            {/**/}
                          </li>
                          {/**/}
                          <li className="pagination-next ng-star-inserted">
                            <a
                              onClick={nextPage}
                              tabIndex={0}
                              className="ng-star-inserted"
                            >
                              {" "}
                              Next <span className="show-for-sr">page</span>
                            </a>
                            {/**/}
                            {/**/}
                          </li>
                          {/**/}
                        </ul>
                        {/**/}
                      </nav>
                    </pagination-template>
                  </pagination-controls>
                </div>
                {/**/}
                {/**/}
              </div>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
      </app-current-bets>
      {/**/}
    </div>
  );
}
