import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClientBetsCricketAPI } from "../service/cricket";
import Select from "react-select";
import moment from "moment";

export default function MatchPosition({ isBookMakerOnly }) {
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [totalLength, setTotalLength] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const [uniqueUser, setUniqueUser] = useState([]);
  var gameType = "cricket";
  var eventId = params?.id;

  const getClientBets = async (betStatus_) => {
    const { response } = await getClientBetsCricketAPI(
      eventId,
      false,
      betStatus_ ? betStatus_ : betStatus
    );
    if (response) {
      setUniqueUser(
        Array.from(new Set(response?.bets?.map((item) => item?.userName)))
      );
      if (selectedClient && selectedClient.value != "null") {
        let filteredData = response;
        filteredData.bets = response.bets.filter(
          (item) => item.userName == selectedClient.value
        );
        setClientBets(filteredData);
      } else {
        setClientBets(response);
      }

      const totalAmount = response?.bets?.reduce(
        (total, item) => total + item.amount,
        0
      );
      setTotalAmount(totalAmount);

      if (
        response.bets.length != 0 &&
        response.bets[0]?.upperLineExposure?.length > 0
      ) {
        setTotalLength(response.bets[0]?.upperLineExposure.length);
      }
    }
  };

  useEffect(() => {
    getClientBets();
  }, [betStatus]);

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-view-matched-bet
        _nghost-ng-c912018052=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c912018052="" className="page_header">
          <h1 _ngcontent-ng-c912018052="" className="page_heading">
            View Match Bets
          </h1>
          <div _ngcontent-ng-c912018052="" className="breadcrumb_block">
            <nav _ngcontent-ng-c912018052="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c912018052="" className="breadcrumb">
                <li _ngcontent-ng-c912018052="" className="breadcrumb-item">
                  <a _ngcontent-ng-c912018052="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li _ngcontent-ng-c912018052="" className="breadcrumb-item">
                  <a _ngcontent-ng-c912018052="">Sport's Betting </a>
                </li>
                <li
                  _ngcontent-ng-c912018052=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  View Match Bets
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div _ngcontent-ng-c912018052="" className="top_btn_block">
          <button
            _ngcontent-ng-c912018052=""
            className="btn btn-primary"
            tabIndex={0}
            onClick={() => getClientBets("MATCHED")}
          >
            Match &amp; Session Plus Minus
          </button>
          <button
            _ngcontent-ng-c912018052=""
            className="btn btn-primary"
            onClick={() => {
              navigate(`/game/displayMatchSession/${eventId}`);
            }}
            tabIndex={0}
          >
            Display Match &amp; Session Bets
          </button>
          <button
            onClick={() => {
              navigate(`/game/i/${eventId}/viewMatchReport`);
            }}
            _ngcontent-ng-c912018052=""
            className="btn btn-primary"
            tabIndex={0}
          >
            Display Match Bets
          </button>
          <button
            onClick={() => {
              navigate(`/game/i/${eventId}/viewSessionReport`);
            }}
            _ngcontent-ng-c912018052=""
            className="btn btn-primary"
            tabIndex={0}
          >
            Display Session Bets
          </button>
        </div>
        <div
          _ngcontent-ng-c912018052=""
          className="Super_master_details_section agentledger_block"
        >
          <div _ngcontent-ng-c912018052="" className="row">
            {/**/}
          </div>
          <div _ngcontent-ng-c912018052="" className="top_details">
            <div
              _ngcontent-ng-c912018052=""
              className="col-sm-12 col-md-6 col-lg-3"
            >
              <div
                _ngcontent-ng-c912018052=""
                className="input_block custom_select_block position-relative"
              >
                <label _ngcontent-ng-c912018052="">Client</label>
                <Select
                  required=""
                  id="name"
                  placeholder="Select Client"
                  name="client"
                  data-select2-id="name"
                  tabIndex={-1}
                  aria-hidden="true"
                  options={uniqueUser?.map((item, index) => ({
                    value: item,
                    label: item,
                  }))}
                  value={selectedClient}
                  onChange={(e) => setSelectedClient(e)}
                />
              </div>
            </div>
          </div>
          <div _ngcontent-ng-c912018052="" className="row g-3">
            <div
              _ngcontent-ng-c912018052=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-12"
            >
              <div _ngcontent-ng-c912018052="" className="custom_table">
                <div _ngcontent-ng-c912018052="" className="table-responsive">
                  <table _ngcontent-ng-c912018052="" className="table">
                    <thead _ngcontent-ng-c912018052="">
                      <tr _ngcontent-ng-c912018052="">
                        <th _ngcontent-ng-c912018052="">Sr</th>
                        <th _ngcontent-ng-c912018052="">Rate</th>
                        <th _ngcontent-ng-c912018052="">Amount</th>
                        <th _ngcontent-ng-c912018052="">Mode</th>
                        <th _ngcontent-ng-c912018052="">Team</th>
                        <th _ngcontent-ng-c912018052="">Client</th>
                        <th _ngcontent-ng-c912018052="">Agent</th>
                        <th _ngcontent-ng-c912018052="">Date &amp; Time</th>
                        {clientBets &&
                          clientBets?.bets &&
                          clientBets?.bets?.length > 0 &&
                          clientBets?.bets?.[0]?.upperLineExposure?.map(
                            (item) => (
                              <th _ngcontent-ng-c912018052="">
                                {item?.selectionName}
                              </th>
                            )
                          )}
                        <th _ngcontent-ng-c912018052="">Winner</th>
                      </tr>
                    </thead>
                    <tbody _ngcontent-ng-c912018052="">{/**/}</tbody>
                    <tfoot _ngcontent-ng-c912018052="">{/**/}</tfoot>
                    <tbody>
                      {clientBets?.bets?.map((item, index) => (
                        <tr _ngcontent-ng-c912018052="">
                          <td _ngcontent-ng-c912018052="">{index + 1}</td>
                          <td _ngcontent-ng-c912018052="">{item?.rate}</td>
                          <td _ngcontent-ng-c912018052="">{item?.amount}</td>
                          <td _ngcontent-ng-c912018052="">
                            {item?.back ? "LAGAI" : "KHAI"}
                          </td>
                          <td _ngcontent-ng-c912018052="">
                            {item?.runnerName}
                          </td>
                          <td _ngcontent-ng-c912018052="">
                            {item?.userName}
                          </td>
                          <td _ngcontent-ng-c912018052="">{item?.agent}</td>
                          <td _ngcontent-ng-c912018052="">
                            {moment(item?.placeDate + "Z").format(
                              "DD-MM-YYYY HH:mm"
                            )}
                          </td>
                          {item?.upperLineExposure?.map((item) => (
                            <td
                              style={{
                                color:
                                  parseFloat(item?.exposure) < 0
                                    ? "red"
                                    : "green",
                              }}
                            >
                              {parseFloat(item?.exposure).toFixed(2)}
                            </td>
                          ))}
                          <td _ngcontent-ng-c912018052="">{item?.winner}</td>
                        </tr>
                      ))}
                      {/**/}
                      <tr _ngcontent-ng-c912018052="">
                        <td _ngcontent-ng-c912018052="" />
                        <td _ngcontent-ng-c912018052="" />
                        <td _ngcontent-ng-c912018052="" />
                        <td _ngcontent-ng-c912018052="" />
                        <td _ngcontent-ng-c912018052="" />
                        <td _ngcontent-ng-c912018052="" />
                        <td _ngcontent-ng-c912018052="" />
                        <td _ngcontent-ng-c912018052="">
                          <b>Total</b>
                        </td>
                        <td _ngcontent-ng-c912018052="">
                          <b
                            style={{
                              color:
                                clientBets?.bets?.reduce(
                                  (total, item) =>
                                    total +
                                    item.upperLineExposure[0]?.exposure,
                                  0
                                ) > 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {" "}
                            {clientBets?.bets?.reduce(
                              (total, item) =>
                                total + item.upperLineExposure[0]?.exposure,
                              0
                            )}
                          </b>
                        </td>
                        <td
                          style={{
                            color: "red",
                          }}
                          _ngcontent-ng-c912018052=""
                        >
                          <b
                            style={{
                              color:
                                clientBets?.bets?.reduce(
                                  (total, item) =>
                                    total +
                                    item.upperLineExposure[1]?.exposure,
                                  0
                                ) > 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {" "}
                            {clientBets?.bets?.reduce(
                              (total, item) =>
                                total + item.upperLineExposure[1]?.exposure,
                              0
                            )}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-view-matched-bet>
      {/**/}
    </div>
  );
}
