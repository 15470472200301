import { AXIOS_INSTANCE } from ".";
import { BASE_URL, getToken } from "../utils/constants";

export const getAccountStatementAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/accmanage/account-log`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getClientAPI = async (
         search,
         page,
         size,
         id,
         userType,
         isBlocked,
         isActive,
         userName
       ) => {
         try {
           const { data } = await AXIOS_INSTANCE.get(
             `${BASE_URL}/admin/list-children`,
             {
               params: {
                 page,
                 size,
                 search,
                 userType,
                 userName: id ? id : null,
                 isBlocked,
                 allActiveDeactive: isActive,
                 userName,
               },
             }
           );
           return data;
         } catch (error) {
           return error;
         }
       };
export const getClientChildAPI = async (
  search,
  page,
  size,
  username,
  userType,
  isBlocked
) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/list-children`,
      {
        params: {
          page,
          size,
          search,
          userType,
          userName: username ? username : null,
          isBlocked,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getUsersWithUserTypeAPI = async (userType) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/${userType.toUpperCase()}/users`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getChildUserInfoAPI = async (userId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/children/${userId}/info`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const searchChildrenAPI = async (search) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/search-children?search=${search}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const addNewClientAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(`${BASE_URL}/auth/signup`, {
      ...payload,
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const EditClientAPI = async (payload, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/admin/children/${id}/edit`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getEarningReportAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/earning-report`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getGlobalSettingsAPI = async (childUserId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/global-settings`,
      {
        params: {
          childUserId: childUserId,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getGeneratedUsernameAPI = async (userType) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/username?userType=${userType.toUpperCase()}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getLedgerAndCoinBalance = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/children/${id}/dashboard`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getUserDataAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/admin/user-info`);
    return data;
  } catch (error) {
    return error;
  }
};

export const ChangeClientPasswordAPI = async (payload, childId) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/admin/children/${childId}/password`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const ChangeClientStatusAPI = async (
         childId,
         isActive,
         isBetLocked,
         isCasinoLocked
       ) => {
         try {
           const { data } = await AXIOS_INSTANCE.put(
             `${BASE_URL}/admin/children/${childId}/status`,
             {},
             {
               params: {
                 isActive,
                 isBetLocked,
                 isCasinoLocked,
               },
             }
           );
           return data;
         } catch (error) {
           return error;
         }
       };
export const ChangeClientLimitAPI = async (payload, childId) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/admin/children/${childId}/limit`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const ChangeClientGlobalSettingAPI = async (payload, childId) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/admin/children/${childId}/global-settings`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const ClientWithdrawAndDepositAPI = async (childId, payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/admin/children/${childId}/deposit-withdraw`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
