import React, { useEffect, useState } from "react";
import {
  getCasinoDetailsAPI,
  getCasinoReportHistoryAPI,
} from "../../service/AuthService";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function CasinoDetails() {
  const [payload, setPayload] = useState({ page: 0, size: 20 });
  const [data, setData] = useState([]);
  const getCasinoDetails = async () => {
    const { response, code } = await getCasinoDetailsAPI(
      payload.page,
      payload.size
    );
    if (code == 200) {
      setData(response);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getCasinoDetails();
  }, [payload]);

  return (
    <div
      className="content-wrapper"
      style={{ minHeight: "1300.41px", marginTop: 100 }}
    >
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Casino Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">
                  Casino Ledger Plus/Minus
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <div action="#" method="post" id="demoForm">
                  <div className="card-header">
                    <a
                      className="btn bg-indigo"
                      href="/casino/casino-bet-report"
                    >
                      Casino Bet Report
                    </a>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length"
                            id="example1_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="example1_length"
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    size: e.target.value,
                                  });
                                }}
                                value={payload.size}
                                aria-controls="example1"
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="example1_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="example1"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            id="example1"
                            className="table table-bordered dataTable no-footer"
                            aria-describedby="example1_info"
                          >
                            <thead>
                              <tr>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="
                                          #
                                      : activate to sort column ascending"
                                >
                                  #
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="SNo: activate to sort column ascending"
                                >
                                  SNo
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-sort="descending"
                                  aria-label="Date Time: activate to sort column ascending"
                                >
                                  Date Time
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Game Type: activate to sort column ascending"
                                >
                                  Game Type
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Plus Minus: activate to sort column ascending"
                                >
                                  Plus Minus
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((item) => (
                                <tr className="odd">
                                  <td>
                                    <div
                                      style={{
                                        width: "min-content",
                                      }}
                                      align="center"
                                    >
                                      <a
                                        className="dropdown-item btn btn-primary"
                                        onClick={() =>
                                          navigate(
                                            `/casino/casino-select-plus-minus-report/${
                                              item?.dateId
                                            }`
                                          )
                                        }
                                      >
                                        Show Details
                                      </a>
                                    </div>
                                  </td>
                                  <td>
                                    <label>{item?.id}</label>
                                  </td>
                                  <td className="sorting_1">
                                    <span>{item?.dateId}</span>
                                  </td>
                                  <td>Casino Ledger</td>
                                  <td>
                                    {parseFloat(item?.profitLoss).toFixed(2)}
                                  </td>
                                </tr>
                              ))}

                              <tr className="even">
                                <th />
                                <th />
                                <th className="sorting_1" />
                                <th />
                                <th>
                                  {parseFloat(
                                    data.reduce((acc, item) => {
                                      return acc + item.profitLoss;
                                    }, 0)
                                  ).toFixed(2)}
                                </th>
                              </tr>
                            </tbody>
                            <tfoot />
                          </table>
                          <div
                            id="example1_processing"
                            className="dataTables_processing card"
                            style={{ display: "none" }}
                          >
                            Processing...
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="example1_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing{" "}
                            {data.length > 0
                              ? payload.page * payload.size + 1
                              : 0}{" "}
                            to {payload.page * payload.size + data.length} of{" "}
                            {data.length} entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example1_paginate"
                          >
                            <ul className="pagination">
                              <li
                                className="paginate_button page-item previous disabled"
                                id="example1_previous"
                              >
                                <a
                                  href="#"
                                  aria-controls="example1"
                                  data-dt-idx={0}
                                  tabIndex={0}
                                  className="page-link"
                                  onClick={() => {
                                    if (payload.page > 0) {
                                      setPayload({
                                        ...payload,
                                        page: payload.page - 1,
                                      });
                                    }
                                  }}
                                >
                                  Previous
                                </a>
                              </li>
                              <li className="paginate_button page-item active">
                                <a
                                  href="#"
                                  aria-controls="example1"
                                  data-dt-idx={1}
                                  tabIndex={0}
                                  onClick={() => {
                                    setPayload({
                                      ...payload,
                                      page: payload.page,
                                    });
                                  }}
                                  className="page-link"
                                >
                                  {payload.page + 1}
                                </a>
                              </li>
                              <li
                                className="paginate_button page-item next disabled"
                                id="example1_next"
                              >
                                <a
                                  href="#"
                                  aria-controls="example1"
                                  data-dt-idx={2}
                                  tabIndex={0}
                                  onClick={() => {
                                    if (data.length == payload.size) {
                                      setPayload({
                                        ...payload,
                                        page: payload.page + 1,
                                      });
                                    }
                                  }}
                                  className="page-link"
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </div>
  );
}
